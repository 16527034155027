//load home css
import(
 /* webpackMode: "eager" */
 /* webpackPrefetch: true */
 './../../css/home.scss'
 );
//load js
import './../../index';
import podcastListingTemplate from "./../../components/podcast/podcasts-listing.handlebars";

// document.addEventListener("DOMContentLoaded", () => {
  var mrsPodcasts = document.getElementById('mrsPodcasts');
  var mrsTesti = document.getElementById('mrsTesti');
  let observer = new IntersectionObserver(featuredObserver, {
    root : document.getElementById('body'),
    rootMargin: '20px',
    threshold: 0
  });
  observer.observe(mrsPodcasts);
  observer.observe(mrsTesti);
  function featuredObserver(entries, observer) {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        const req = new Request(WPURL+"wp-json/mrs/v1/featured", { priority: "low" });
        fetch(req)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Something went wrong on API server!");
          }
        })
        .then((response) => {
          // do something with the returned posts
          mrsPodcasts.innerHTML = podcastListingTemplate({ podcasts: response.podcasts });
          import(
            /* webpackMode: "lazy" */
            /* webpackPrefetch: true */
            /* webpackChunkName: "testimonial/testimonial" */
            './../../components/testimonial/testimonial.js'
          ).then(testimonialService => {
            var homeTesti = new testimonialService.default();
            homeTesti.setDiv('mrsTesti');
            homeTesti.setCarousel(true);
            homeTesti.initCarouselData(response.testimonials);
          });
          observer.disconnect();
        })
        .catch((error) => {
          console.error(error);
        });
      }
    });
  }
// });
